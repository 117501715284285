export const afi100 = [
  "Frankly, my dear, I don't give a damn.",
  "I'm gonna make him an offer he can't refuse.",
  "I coulda been a contender.",
  "Toto, I've a feeling we're not in Kansas anymore.",
  "Here's looking at you, kid.",
  "Go ahead, make my day.",
  "All right, Mr. DeMille, I'm ready for my close-up.",
  "May the Force be with you.",
  "Fasten your seatbelts. It's going to be a bumpy night.",
  "You talkin' to me?",
  "What we've got here is failure to communicate.",
  "I love the smell of napalm in the morning.",
  "Love means never having to say you're sorry.",
  "The stuff that dreams are made of.",
  "E.T. phone home.",
  "They call me Mister Tibbs!",
  "Rosebud.",
  "Made it, Ma! Top of the world!",
  "I'm as mad as hell, and I'm not going to take this anymore!",
  "Louis, I think this is the beginning of a beautiful friendship.",
  "I ate his liver with some fava beans and a nice Chianti.",
  "Bond. James Bond.",
  "There's no place like home.",
  "I am big! It's the pictures that got small.",
  "Show me the money!",
  "Why don't you come up sometime and see me?",
  "I'm walkin' here!",
  "Play it, Sam. Play 'As Time Goes By.'",
  "You can't handle the truth!",
  "I want to be alone.",
  "After all, tomorrow is another day!",
  "Round up the usual suspects.",
  "I'll have what she's having.",
  "You just put your lips together and blow.",
  "You're gonna need a bigger boat.",
  "Badges? I don't have to show you any stinking badges!",
  "I'll be back.",
  "Today, I consider myself the luckiest man on the face of the Earth.",
  "If you build it, he will come.",
  "My mama always said life was like a box of chocolates.",
  "We rob banks.",
  "Plastics.",
  "We'll always have Paris.",
  "I see dead people.",
  "Stella! Hey, Stella!",
  "Don't let's ask for the moon. We have the stars.",
  "Shane. Shane. Come back!",
  "Well, nobody's perfect.",
  "It's alive! It's alive!",
  "Houston, we have a problem.",
  "You've got to ask yourself one question: 'Do I feel lucky?'",
  "You had me at 'hello.'",
  "There's no crying in baseball!",
  "La-dee-da, la-dee-da.",
  "A boy's best friend is his mother.",
  "Greed, for lack of a better word, is good.",
  "Keep your friends close, but your enemies closer.",
  "As God is my witness, I'll never be hungry again.",
  "Well, here's another nice mess you've gotten me into!",
  "Say 'hello' to my little friend!",
  "Mrs. Robinson, you're trying to seduce me. Aren't you?",
  "Gentlemen, you can't fight in here! This is the War Room!",
  "Elementary, my dear Watson.",
  "Take your stinking paws off me, you damned dirty ape.",
  "Of all the gin joints in all the towns in all the world, she walks into mine.",
  "Here's Johnny!",
  "They're here!",
  "Is it safe?",
  "You ain't heard nothin' yet!",
  "No wire hangers, ever!",
  "Mother of mercy, is this the end of Rico?",
  "Forget it, Jake, it's Chinatown.",
  "I have always depended on the kindness of strangers.",
  "Hasta la vista, baby.",
  "Soylent Green is people!",
  "Open the pod bay doors, HAL.",
  "I am serious … and don't call me Shirley.",
  "Yo, Adrian!",
  "Hello, gorgeous.",
  "Toga! Toga!",
  "Listen to them. Children of the night. What music they make.",
  "It was Beauty killed the Beast.",
  "My precious.",
  "Attica! Attica!",
  "Win just one for the Gipper.",
  "A martini. Shaken, not stirred.",
  "Who's on first.",
  "It's in the hole! It's in the hole! It's in the hole!",
  "Life is a banquet, and most poor suckers are starving to death!",
  "I feel the need—the need for speed!",
  "Carpe diem. Seize the day, boys.",
  "Snap out of it!",
  "Nobody puts Baby in a corner.",
  "I'll get you, my pretty, and your little dog too!",
  "I'm the king of the world!",
];
